$primary: #975aff !default;
$success: #40c057 !default;
$info: #2f8be6 !default;
$warning: #f77e17 !default;
$danger: #f55252 !default;
$secondary: #616d89 !default;
$light: #dbdee5 !default;
$dark: #1a051d !default;

$input-bg: #fff !default;
$dl-input-bg: #1e1e1e !default;
