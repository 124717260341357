// Global Overrides Template Level Only

.width-1-per {
  width: 1%;
}
//------- ng-accordion -------
.accordion {
  .card {
    margin: 0;
    background-color: transparent !important;

    &:not(:last-of-type) {
      .card-header {
        border-bottom: 1px solid $border-color;
      }
    }

    &:last-of-type {
      .card-header button {
        padding-bottom: 0;
      }

      .card-body {
        padding-bottom: 0;
      }
    }

    .card-header {
      padding: 0;

      button {
        padding: 1.5rem;
        width: 100%;
        text-align: start;

        &:hover {
          box-shadow: none;
        }
      }
    }
  }
}

// for dark and transparent layouts
html body.layout-dark {
  .accordion .card {
    box-shadow: none;
  }

  &:not(.layout-transparent) {
    .accordion .card .card-header {
      border-color: $dl-border-color;
    }
  }

  &.layout-transparent {
    .accordion .card .card-header {
      border-color: $tl-border-color;

      button:not([disabled]) {
        color: map-get($primary-color, lighten-4);
      }
    }
  }
}

//------- horizontal timeline -------
.cd-horizontal-timeline .events-content li {
  transform: translateX(0);
}

//------- time picker -------
.ngb-tp-input-container button:hover {
  box-shadow: none;
}

//------- Chartist Tooltip -------
.chartist-tooltip:not(.tooltip-show) {
  padding: 0;

  &:before {
    border: 0;
  }
}

.form-control {
  background-color: $input-bg;

  &:focus {
    background-color: $input-bg;
  }
}

.wrapper {
  height: auto;
  min-height: auto;
  overflow: visible;

  .main-panel .main-content {
    min-height: calc(var(--vh, 1vh) * 100 - 7.52rem);
  }
}

.btn:disabled {
  cursor: default !important;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
