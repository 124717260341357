// Overrides user components-variables
@import "bootstrap/scss/functions";

@import "../variables/variables";
@import "bootstrap/scss/variables";
$white-color: (
  "base": #ffffff,
);
$black-color: (
  "base": #000000,
);
$primary-color: (
  "lighten-4": lighten($primary, 80%),
  "lighten-3": lighten($primary, 60%),
  "lighten-2": lighten($primary, 40%),
  "lighten-1": lighten($primary, 20%),
  "base": $primary,
  "darken-1": darken($primary, 20%),
  "darken-2": darken($primary, 40%),
  "darken-3": darken($primary, 60%),
  "darken-4": darken($primary, 80%),
);
$secondary-color: (
  "lighten-4": lighten($secondary, 80%),
  "lighten-3": lighten($secondary, 60%),
  "lighten-2": lighten($secondary, 40%),
  "lighten-1": lighten($secondary, 20%),
  "base": $secondary,
  "darken-1": darken($secondary, 20%),
  "darken-2": darken($secondary, 40%),
  "darken-3": darken($secondary, 60%),
  "darken-4": darken($secondary, 80%),
);
$success-color: (
  "lighten-4": lighten($success, 80%),
  "lighten-3": lighten($success, 60%),
  "lighten-2": lighten($success, 40%),
  "lighten-1": lighten($success, 20%),
  "base": $success,
  "darken-1": darken($success, 20%),
  "darken-2": darken($success, 40%),
  "darken-3": darken($success, 60%),
  "darken-4": darken($success, 80%),
);
$danger-color: (
  "lighten-4": lighten($danger, 80%),
  "lighten-3": lighten($danger, 60%),
  "lighten-2": lighten($danger, 40%),
  "lighten-1": lighten($danger, 20%),
  "base": $danger,
  "darken-1": darken($danger, 20%),
  "darken-2": darken($danger, 40%),
  "darken-3": darken($danger, 60%),
  "darken-4": darken($danger, 80%),
);
$warning-color: (
  "lighten-4": lighten($warning, 80%),
  "lighten-3": lighten($warning, 60%),
  "lighten-2": lighten($warning, 40%),
  "lighten-1": lighten($warning, 20%),
  "base": $warning,
  "darken-1": darken($warning, 20%),
  "darken-2": darken($warning, 40%),
  "darken-3": darken($warning, 60%),
  "darken-4": darken($warning, 80%),
);
$info-color: (
  "lighten-4": lighten($info, 80%),
  "lighten-3": lighten($info, 60%),
  "lighten-2": lighten($info, 40%),
  "lighten-1": lighten($info, 20%),
  "base": $info,
  "darken-1": darken($info, 20%),
  "darken-2": darken($info, 40%),
  "darken-3": darken($info, 60%),
  "darken-4": darken($info, 80%),
);
$light: (
  "base": #dbdee5,
);
$dark: (
  "base": #1a051d,
);

$grey-color: (
  "lighten-4": #f5f5f5,
  "lighten-3": #eee,
  "lighten-2": #e0e0e0,
  "lighten-1": #bdbdbd,
  "base": #9e9e9e,
  "darken-1": #757575,
  "darken-2": #616161,
  "darken-3": #424242,
  "darken-4": #212121,
);

$shades: (
  "black": #000000,
  "white": #ffffff,
  "transparent": transparent,
);

$colors: (
  "white": $white-color,
  "black": $black-color,
  "primary": $primary-color,
  "secondary": $secondary-color,
  "success": $success-color,
  "danger": $danger-color,
  "warning": $warning-color,
  "info": $info-color,
  "light": $light,
  "dark": $dark,
  "grey": $grey-color,
);

$gradient-colors: (
  gradient-mint: (
    "start-color": #226cc5,
    "end-color": #6cd975,
  ),
  gradient-pomegranate: (
    "start-color": #465375,
    "end-color": #f55252,
  ),
  gradient-blackberry: (
    "start-color": #303c62,
    "end-color": #b5c3db,
  ),
  gradient-king-yna: (
    "start-color": #7441db,
    "end-color": #f9877c,
  ),
  gradient-ibiza-sunset: (
    "start-color": #b02940,
    "end-color": #fcc173,
  ),
  gradient-flickr: (
    "start-color": #f77e17,
    "end-color": #2f8be6,
  ),
  gradient-purple-bliss: (
    "start-color": #562db7,
    "end-color": #60aff0,
  ),
  gradient-man-of-steel: (
    "start-color": #2f3ea0,
    "end-color": #ae342d,
  ),
  gradient-purple-love: (
    "start-color": #7441db,
    "end-color": #c89cff,
  ),
  gradient-starfall: (
    "start-color": #d23b48,
    "end-color": #2f8be6,
  ),
);
