@import "../core/variables/variables";

angular-editor {
  flex-grow: 1;
  .angular-editor .angular-editor-wrapper .angular-editor-textarea {
    margin-top: 0 !important;
    border-top: 0 !important;
    background-color: $white !important;
    border-bottom-left-radius: $border-radius !important;
    border-bottom-right-radius: $border-radius !important;
  }

  .angular-editor .angular-editor-toolbar {
    border-top-left-radius: $border-radius !important;
    border-top-right-radius: $border-radius !important;
    display: flex;
  }
}

.is-invalid angular-editor {
  .angular-editor .angular-editor-wrapper .angular-editor-textarea {
    border-left-color: $danger !important;
    border-right-color: $danger !important;
    border-bottom-color: $danger !important;
  }

  .angular-editor .angular-editor-toolbar {
    border-top-color: $danger !important;
    border-left-color: $danger !important;
    border-right-color: $danger !important;
  }
}
